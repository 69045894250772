import React from 'react';
import {graphql, Link} from 'gatsby';
import Image from 'gatsby-image';
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import BlogSidebar from '../components/BlogSidebar';

import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import COLORS from '../utils/colors';
import SIZES from '../utils/sizes';
import '../styles/templates.scss';
import styled from 'styled-components';



const SinglePost = styled.section`

    margin-bottom:96px;

    h1{
        text-align: center;
        margin: 24px 0;
        width:100%;
    }

    .blog-post-img{       
        width:100%;
        border-radius: 4px;
        height:400px;  
    }

    .credits{
        font-size:1rem;
        display:inline-block;     
        text-align:right;
        width:100%;

        a{
            font-size:0.8rem;
        }
    }

    >section{
        width:100%;
        display:flex;
        justify-content:space-between;
        margin-top:56px;

        
        article.custom-post-content
        {
            width:calc(100% / 3 * 2 - 48px);
            text-align:justify;           
    
    
            h2{
              
                margin: 3rem 0 0;
                font-family: "Catamaran", Arial, Helvetica, sans-serif;
                font-weight:700;
                text-transform: none;
                font-size: 2.2rem;
                line-height: 2.6rem;
                letter-spacing:0px;
                padding: 8px 0;
         

                :before{
                    content:"|";
                    display:inline-block;
                    margin-right:16px;
                    color:${COLORS.thirdaryHover};
                   
                }

            }

            

            h3
            {
                margin-top: 2rem;
                font-size: 1.6rem;
                font-family: "Catamaran", Arial, Helvetica, sans-serif;
                font-weight:700;
            }

            h4
            {
                margin-top:2.8rem;
                text-transform:uppercase;
                font-weight:700;
            }
    
            p,
            .single-post-content-img
            {
                margin-top: 1rem;
                width:100%;
            }

            .single-post-content-img-container
            {
                margin:48px 0;
            }

            ul,
            ol
            {
                margin-left:48px;
            }
    
    
            ul > li > p,
            ol > li > p,
            {
                margin-top:0;
            }
            
            .video-container
            {
                margin: 24px 0 96px 0;
                position: relative;
                /*ratio video*/
                padding-bottom:56.25%;

                iframe
                {
                    position:absolute;
                    top:0;
                    left:0;
                }
            }
        }

            
    }
    

    @media (max-width: ${SIZES.firstBreakPoint}){
            
        article {
            margin: 0 0;          
        
        }    

        >section
        {
            flex-direction:column;
            margin-top:0;

            article.custom-post-content
            {
                width:100%;
                padding: 24px 48px;
            }
        }
    }

    @media (max-width: ${SIZES.secondBreakPoint}){
        article{
            margin: 0 0;

           
        } 
    }

    @media (max-width: ${SIZES.thirdBreakPoint}){

        .blog-post-top{
           
            .blog-post-info{
           
                bottom:16px;
            }
        }

        h1{
            font-size:3.2rem;
            margin:8px 0;
        }
            
        .blog-post-img{
            height:25vh;
          
        }
        >section article.custom-post-content
            {
                text-align:center; 
                padding: 24px 0px;

                ul,
                ol
                {
                    margin-left:16px;
                }
            }

        }
        
    }
`

const ArticleMetaList = styled.ul`

    margin-bottom:16px;

    list-style-type:none;
    display:flex;
    flex-wrap:nowrap;
    justify-content: center;

    li:first-child{
        margin-right:24px;
    }

    li{

        span{
            font-size:1rem;
     
        }


        span.date::before{

            font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f073";
            display:inline-block;
            margin-right:8px;
            color:${COLORS.primary};
    
        }
    
        span.author::before{
    
            font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f007";
            display:inline-block;
            margin-right:8px;
            color:${COLORS.primary};
        }
    }

    
    

`


export const query = graphql`
    query($slug:String!){
        contentfulBlogArticle(slug:{eq: $slug}) {
            title
            pubDate(formatString: "DD MMM YYYY", locale: "fr")
            slug
            content{
                raw
                references {
                    ... on ContentfulAsset {
                      __typename
                      contentful_id
                      title
                      description
                      fluid(maxWidth: 10) {
                        src
                      }
                      gatsbyImageData
                    }
                }
            }
            featImage {
                id
                title
                description
                file{
                  url
                }
                
                fluid(maxWidth:1600){
                  ...GatsbyContentfulFluid
                }
            }
        }
    }    
`

const Blog = (props) => {

    const options = {
        renderNode:{
            [BLOCKS.EMBEDDED_ASSET]: node => {
                console.log(node)
                const { gatsbyImageData } = node.data.target
                let legendeImg =''
                node.data.target.description ? legendeImg = <span className="credits">Crédit image: <a href={node.data.target.description}>{node.data.target.description}</a></span> : legendeImg = <span className="credits">{node.data.target.title}</span>
            
                if (!gatsbyImageData) {
                    // asset is not an image
                    return null
                }
                
                return (
                    <div className="single-post-content-img-container">
                        <GatsbyImage className="single-post-content-img" image={gatsbyImageData} alt={node.data.target.title} objectFit="contain" />
                        {legendeImg}
                    </div>
                )
            },
            [INLINES.HYPERLINK]: node => {
                // Only process youtube links
                if (node.data.uri.includes("youtube.com")) {
                    // Extract videoId from the URL
                    const match = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/.exec(
                        node.data.uri
                    )
                    const videoId =
                        match && match[7].length === 11 ? match[7] : null
                    return (
                        videoId && (
                        <div className="video-container">
                            <iframe
                                className="video"
                                title={`https://youtube.com/embed/${videoId}`}
                                src={`https://youtube.com/embed/${videoId}`}
                                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                width={'100%'}
                                height={'100%'}
                                allowFullScreen
                            />
                        </div>
                        )
                    )
                }
                else {
                    return (
                        <a href={node.data.uri} target="_blank">{node.content[0].value}</a>
                    )
                }
            },
            /*"embedded-asset-block":(node) => {
                const alt = node.data.target.fields.title['en-US']
                const url = node.data.target.fields.file['en-US'].url
                const desc = node.data.target.fields.description['en-US']
                return <figure><img className="single-post-content-img" alt={alt} src={url}/><figcaption>{desc}</figcaption></figure>
            }*/
        }
    }

    const { next, prev } = props.pageContext
    const post = props.data.contentfulBlogArticle

    return (
        <Layout theme="blog" page="blog">
            <SEO title={post.title}/>
            
            <SinglePost>
                <div className="blog-post-top">
                    <div className="blog-post-info">
                        <h1 className="single-title">{post.title}</h1>
                        <ArticleMetaList>
                            <li><span className="date">{post.pubDate}</span></li>
                            <li><span className="author">Marylise</span></li>
                        </ArticleMetaList> 
                    </div>
                    <Image className="blog-post-img"
                        fluid={post.featImage.fluid}
                        alt={post.featImage.title}
                        imgStyle= { {objectFit:'cover'} }  
                    />
                    <span className="credits">Crédit image: <a href={post.featImage.description}>{post.featImage.description}</a></span>
                </div>
                <section>
                    <article className="custom-post-content">
                        {
                            renderRichText(post.content, options)
                        }

                    </article>

                    <BlogSidebar />
                </section>
                

            </SinglePost>
            <div className="prev-next">
                { next && <Link className="icon next" to={`/blog/${next.slug}`}>Précédent</Link> }
                { prev && <Link className="icon previous" to={`/blog/${prev.slug}`}>Suivant</Link> }
             </div>
            
        </Layout>
    );
};

export default Blog;