import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'
import BlockRSO from '../components/BlockRSO'
import CardLink from './CardLink'
import SIZES from '../utils/sizes'
import COLORS from '../utils/colors'


const StyledBlogSidebar = styled.article`{
    width:336px;
    margin-top: 48px;
            

    >ul
    {
        margin: 0 0 0 0;
        list-style-type:none;
        display flex;
        flex-direction:column;
        gap:48px;

        >li
        {
            background: radial-gradient(circle, rgba(255,244,227,1) 22%, rgba(249,233,208,1) 77%, rgba(248,222,175,1) 100%);
            border-radius:4px;

            padding:12px 24px 0;
                    
            >span
            {
                text-transform:lowercase;
                font-size:0.8rem;
                font-weight:700;
                letter-spacing:1px;
                color:#167bfa;                        

                &:after
                {
                    content:"|";
                    margin-left:16px;
                }
            }

            h3
            {
                font-family: "Gilroy Light", "Catamaran", Arial, Helvetica, sans-serif;
                font-weight:bold;
                font-size: 1.2rem;
                letter-spacing: 2px;
                text-transform:uppercase;

                i
                {
                    font-size:1.8rem;
                   
                }
                
            }


            h4
            {
                font-size:1rem;
            }

            div
            {
                margin:24px 0;
                p
                {
                    font-size:1rem;
                }

                >ul li
                {
                    list-style-type:none;
                    display:flex;
                    align-items:center;
                    justify-content:flex-start;

                    .latest-post-thumbnail
                    {
                        width:56px;
                        height:56px;
                        border-radius:4px;
                        margin-right:24px;
                    }

                    div{
                        margin: 8px 0;
                        width:calc(100% - 80px);
                        span
                        {
                            font-size:0.8rem;
                        }
                    }
                    
                    
                }

                
            }
        }
    }
        
}

@media (max-width: ${SIZES.firstBreakPoint}){
    width:100%;

    >ul
    {
        flex-direction:row;
        justify-content:space-between;
        >li
        {
            width:calc(100% / 3 - 24px);
            padding:16px 24px 0;
        }
    }

}

@media (max-width: ${SIZES.thirdBreakPoint}){
    >ul
    {
        flex-direction:column;
        >li{
            width:100%;
        }
    }
}
`

/** Requête graphql pour les 3 derniers articles */
const getLatestPosts = graphql`
  query{

      allContentfulBlogArticle(
        limit:3
        sort: {fields: pubDate, order: DESC}
        ) {
          edges {
            node {
              id
              title
              slug
              pubDate(formatString: "DD MMM YYYY", locale: "fr")
              excerpt {
                excerpt
              }
              
              featImage {
                id
                title
                file{
                  url
                }
                fluid{
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
      }
  }
    
`

const BlogSidebar = () => {

    const data = useStaticQuery(getLatestPosts)
    return (
        <StyledBlogSidebar>
            <ul>
                <li>
                    <span>actu</span>
                    <h3>Derniers articles</h3>
                    <div>
                        <ul>
                        {data.allContentfulBlogArticle.edges.map((edge) => {
                  
                            return (
                                <li>
                                    <Image className="latest-post-thumbnail"
                                        fluid={edge.node.featImage.fluid}
                                        alt={edge.node.featImage.title}
                                        imgStyle= { {objectFit:'cover'} } 
                                    />
                                    <div>
                                        <span>{edge.node.pubDate}</span>
                                        <Link to={`/blog/${edge.node.slug}`}><h4>{edge.node.title}</h4></Link>
                                    </div>
                                </li>
                            )

                        })} 
                        </ul> 
                    </div>
                </li>
                <li>
                    <span>créa</span>
                    <h3>Réalisations</h3>
                    <div>
                        <p>Bienvenue sur mon espace blog ! Mais au fait, avez-vous feuilleté mon portfolio ?</p>
                        <CardLink />
                    </div>
                </li>
                <li>
                    <span>stay tuned</span>
                    <h3><i class="far fa-hand-spock"></i></h3>
                    <div>
                        <p>Rejoignez-moi sur les réseaux sociaux pour le grand plongeon dans le monde du graphisme web !</p>
                        <BlockRSO/>
                    </div>
                </li>                            
            </ul>
        </StyledBlogSidebar>
    );
};

export default BlogSidebar;